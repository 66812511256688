import React from 'react'


/* This example requires Tailwind CSS v2.0+ */
export default function Header(props) {
    return (
        <div className="bg-white mt-8">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    {/* <h2 className="text-base font-semibold text-theme tracking-wide uppercase">Pricing</h2> */}
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        {props.title}
                    </p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                        {props.intro_text}
                    </p>
                </div>
            </div>
        </div>
    )
}
