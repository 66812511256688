import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage, className } from "gatsby-plugin-image"
import Img from "gatsby-image"


const Image = ({ src, alt, className }) => {
    const image = getImage(src)
    return (
        <GatsbyImage className={className} imgClassName={className} image={image} alt={alt} />
    )

    // <StaticImage
    //     src={path}
    //     alt={alt}
    //     placeholder="blurred"
    //     layout="fixed"
    //     // width={200}
    //     // height={200}
    // />
}

export default Image