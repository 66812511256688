import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"


/* This example requires Tailwind CSS v2.0+ */
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import Header from './Header'
import VorteilLinks from './Vorteil_links'
import VorteilRechts from './Vorteil_rechts'

export default function FeaturesBig() {
    return (
        <StaticQuery query={pageQuery}
            render={data => {
                const {
                    title,
                    intro_text,
                    vorteil_1, vorteil_2, vorteil_3, vorteil_4
                } = data.markdownRemark.frontmatter
                const vorteile = [vorteil_1, vorteil_2, vorteil_3, vorteil_4]
                return (
                    <>
                        <Header title={title} intro_text={intro_text} />
                        <div className="relative bg-white pt-8 pb-32 overflow-hidden">

                            {vorteile.map((vorteil, index) => {
                                const { title, text, bild } = vorteil
                                if ((index % 2) == 0) {
                                    return (
                                        <VorteilLinks
                                            title={title}
                                            text={text}
                                            image={bild}
                                        />
                                    )
                                } else {
                                    return (
                                        <VorteilRechts
                                            title={title}
                                            text={text}
                                            image={bild}
                                        />
                                    )
                                }
                            })}
                        </div>
                    </>

                )
            }}
        />
    )
}

export const pageQuery = graphql`
query IndexVorteile {
    markdownRemark(frontmatter: { templateKey: { eq: "vorteile-page" } }) {
   frontmatter {
           title
   intro_text
   vorteil_1 {
     title
     text
     bild {
        childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
     }
   }
   vorteil_2 {
     title
     text
     bild {
        childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
     }
   }
   vorteil_3 {
     title
     text
     bild {
        childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
     }        	
   }
   vorteil_4 {
     title
     text
     bild {
        childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
    }
   }
 }
}
}
                            `