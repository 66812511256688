import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"

/* This example requires Tailwind CSS v2.0+ */
import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Competitive rates',
        description: 'Consequuntur omnis dicta cumque, inventore atque ab dolores aspernatur tempora ab doloremque.',
        icon: GlobeAltIcon,
    },
    {
        name: 'No hidden fees',
        description:
            'Corporis quisquam nostrum nulla veniam recusandae temporibus aperiam officia incidunt at distinctio ratione.',
        icon: ScaleIcon,
    },
    {
        name: 'Instant transfers',
        description:
            'Omnis, illo delectus? Libero, possimus nulla nemo tenetur adipisci repellat dolore eligendi velit doloribus mollitia.',
        icon: LightningBoltIcon,
    },
    {
        name: 'Reminder emails',
        description:
            'Veniam necessitatibus reiciendis fugit explicabo dolorem nihil et omnis assumenda odit? Quisquam unde accusantium.',
        icon: MailIcon,
    },
]

export default function FeaturesSmall() {
    return (
        <StaticQuery query={pageQuery}
            render={data => {
                const {
                    title,
                    intro_text,
                    dienstleistung_1, dienstleistung_2, dienstleistung_3, dienstleistung_4
                } = data.markdownRemark.frontmatter
                const dienstleistungen = [dienstleistung_1, dienstleistung_2, dienstleistung_3, dienstleistung_4]
                return (
                    <div className="bg-gray-50 overflow-hidden">
                        <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                            <svg
                                className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
                                width={404}
                                height={784}
                                fill="none"
                                viewBox="0 0 404 784"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
                            </svg>

                            <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                                <div className="lg:col-span-1">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        {title}
                                    </h2>
                                    <p className="max-w-xl mt-5 pr-2 mx-auto text-xl text-gray-500">
                                        {intro_text}
                                    </p>
                                </div>
                                <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
                                    {dienstleistungen.map((dienstleistung) => (
                                        <div key={dienstleistung.title}>
                                            <dt>
                                                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-theme text-white">
                                                    <LightningBoltIcon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{dienstleistung.title}</p>
                                            </dt>
                                            <dd className="mt-2 text-base text-gray-500">{dienstleistung.text}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                )
            }
            }
        />
    )
}
export const pageQuery = graphql`
query Dienstleistungen {
    markdownRemark(frontmatter: { templateKey: { eq: "dienstleistungen-page" } }) {
   frontmatter {
           title
   intro_text
   dienstleistung_1 {
     title
     text
   }
   dienstleistung_2 {
     title
     text
   }
   dienstleistung_3 {
     title
     text       	
   }
   dienstleistung_4 {
     title
     text
   }
 }
}
}
`