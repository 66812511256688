import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import FeaturesBig from '../components/FeaturesBig'
import FeaturesSmall from '../components/FeaturesSmall'
import CTA from '../components/CTA'
import Team from '../components/Team'
import Contact from '../components/Contact'
import Blog from '../components/Blog'

export const IndexPageTemplate = ({
  hero_image,
  title,
  highlight,
  text,
  button_left,
  button_right,
}) => (
  <div>
    <Hero
      hero_image={hero_image}
      title={title}
      highlight={highlight}
      text={text}
      button_left={button_left}
      button_right={button_right}
    />
    <FeaturesBig />
    <FeaturesSmall />
    <CTA />
    <Blog />
    <Team />
    <Contact />
  </div>
)

IndexPageTemplate.propTypes = {
  hero_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  highlight: PropTypes.string,
  text: PropTypes.string,
  button_left: PropTypes.object,
  button_right: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        hero_image={frontmatter.hero_image}
        title={frontmatter.title}
        highlight={frontmatter.highlight}
        text={frontmatter.text}
        button_left={frontmatter.button_left}
        button_right={frontmatter.button_right}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  hero_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  highlight: PropTypes.string,
  text: PropTypes.string,
  button_left: PropTypes.object,
  button_right: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero_image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
        }
        title
        highlight
        text
        button_left {
		  label
          link
        }
        button_right {
          label
          link
        }
      }
    }
  }
`
