import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"

/* This example requires Tailwind CSS v2.0+ */
import { ExternalLinkIcon } from '@heroicons/react/solid'
import Image from './Image'

export default function CTA() {
    return (
        <StaticQuery query={pageQuery}
            render={data => {

                const {
                    title,
                    subtitle,
                    highlight,
                    text,
                    image,
                    button
                } = data.markdownRemark.frontmatter

                return (
                    <div className="relative bg-gray-800">
                        <div className="h-56 bg-theme sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                            <Image
                                className="w-full h-full object-cover"
                                src={image}
                                alt={title}
                        />
                        </div>
                        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                            <div className="md:ml-auto md:w-1/2 md:pl-10">
                                <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">{title}</h2>
                                <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">{subtitle}</p>
                                <p className="mt-3 text-lg text-gray-300">
                                    {text}
                                </p>
                                <div className="mt-8">
                                    <div className="inline-flex rounded-md shadow">
                                        <a
                                            href={button.link}
                                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                                        >
                                            {button.label}
                                            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            }
        />

    )
}

export const pageQuery = graphql`
query CTA {
    markdownRemark(frontmatter: { templateKey: { eq: "cta-page" } }) {
   frontmatter {
  	title
  	subtitle
    highlight
    text
    image {
        childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              )
          }
    }
    button {
			label
      link
    }
    
 }
}
}
`