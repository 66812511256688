import React, { Fragment } from 'react'
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import Image from './Image'


const VorteilRechts = (props) => {

    const {
        title, text, image
    } = props

    return (
        <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                    <div>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-theme">
                                <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                {title}
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                                {text}
                            </p>
                            <div className="mt-6">
                                <a
                                    href="#"
                                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-theme hover:bg-themeHover"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                    <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <Image
                            src={image}
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                            alt="Inbox user interface"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VorteilRechts