import React, { Fragment } from 'react'
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import Image from './Image'


const VorteilLinks = (props) => {

    const {
        title, text, image
    } = props

    return (
        <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                    <div>
                        <div>
                            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-theme">
                                <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="mt-6">
                            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                {title}
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                                {text}
                            </p>
                            <div className="mt-6">
                                <a
                                    href="#"
                                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-theme hover:bg-themeHover"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-8 border-t border-gray-200 pt-6">
                        <blockquote>
                            <div>
                                <p className="text-base text-gray-500">
                                    &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                                    donec aliquet. Mi venenatis in euismod ut.&rdquo;
                                </p>
                            </div>
                            <footer className="mt-3">
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-6 w-6 rounded-full"
                                            src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                            alt=""
                                        />
                                    </div>
                                    <div className="text-base font-medium text-gray-700">Marcia Hill, Digital Marketing Manager</div>
                                </div>
                            </footer>
                        </blockquote>
                    </div> */}
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                        <Image
                            src={image}
                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                            alt="Inbox user interface"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VorteilLinks