import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"
import TeamList from './TeamList'

/* This example requires Tailwind CSS v2.0+ */

export default function Team() {
    return (
        <StaticQuery query={pageQuery}
            render={data => {
                const { title, text } = data.markdownRemark.frontmatter

                return (
                    <div className="bg-white">
                        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
                            <div className="space-y-12">
                                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{title}</h2>
                                    <p className="text-xl text-gray-500">
                                        {text}
                                    </p>
                                </div>
                                <ul
                                    role="list"
                                    className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
                                >
                                    <TeamList />
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }} />
    )
}

export const pageQuery = graphql`
query TeamQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "team-page"}}) {
      frontmatter {
        title
        text
      }
    }
  }  
`